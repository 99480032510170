const isMenuOpenedSelector = (state) => {
   return state.common.menu.isMenuOpened;
};

const authUserSelector = (state) => {
   return state.common.authUser;
};

const appIdSelector = (state) => {
   return state.common.authUser.app_id;
};

const siteNameSelector = (state) => {
   return state.common.authUser.site_name;
};

const siteInfoSelector = (state) => {
   return state.common.siteInfo;
};

const isSiteInitedSelector = (state) => {
   return state.common.isSiteInited;
};

const isSiteDetailsRefreshingSelector = (state) => {
   return state.common.isSiteDetailsRefreshing;
};

const isMenuAnimationSelector = (state) => {
   return state.common.menu.animationClass;
};

const screenWidthSelector = (state) => {
   return state.common.screenWidth;
};
const extendedModeSelector = (state) => {
   return state.common.extendedMode;
};
const siteStepsSelector = (state) => {
   return state.common.siteSteps;
};
const viewAsModalSelector = (state) => {
   return state.common.viewAsModal;
};
const isOnlineStreamSelector = (state) => {
   return !!state.common.liveStreamCurrentSessionState?.data;
};

const siteDomainDetailsSelector = (state) => {
   return state.common.siteDomainDetails;
};

const twitterAccountSelector = (state) => {
   return state.common.siteInfo ? state.common.siteInfo.twitter_account : null;
}
const liveStreamCurrentSessionStateSelector = (state) => {
   return state.common.liveStreamCurrentSessionState;
}
const contentMetaInfoSelector = (state) => {
   return state.common.siteInfo?.content_meta_info || {};
}
const isOpenOnlineMembersModalSelector = (state) => {
   return state.common.isOpenOnlineMembersModal;
}
const isUpdateSiteLongInfoSelector = (state) => {
   return state.common.isUpdateSiteLongInfo;
}
const siteLongNameUpdateErrorSelector = (state) => {
   return state.common.siteLongNameUpdateError;
}
const massMessageLimitSelector = (state) => {
   return state.common.massMessageLimit;
}
const isUpdateOfflineModeSelector = (state) => {
   return state.common.isUpdateOfflineMode;
}

const receivedOrdersCount = (state) => {
   return state.common.received_orders_badge_count
}

export {
   isMenuOpenedSelector,
   authUserSelector,
   isSiteInitedSelector,
   isMenuAnimationSelector,
   siteInfoSelector,
   isSiteDetailsRefreshingSelector,
   screenWidthSelector,
   appIdSelector,
   siteStepsSelector,
   viewAsModalSelector,
   siteNameSelector,

   isOnlineStreamSelector,
   siteDomainDetailsSelector,
   twitterAccountSelector,
   liveStreamCurrentSessionStateSelector,
   contentMetaInfoSelector,
   isOpenOnlineMembersModalSelector,
   isUpdateSiteLongInfoSelector,
   siteLongNameUpdateErrorSelector,
   massMessageLimitSelector,
   receivedOrdersCount,
   isUpdateOfflineModeSelector,
   extendedModeSelector,
}
