export const SIGN_UP = 'signUp/SIGN_UP';
export const SIGN_UP_COMPLETED = 'signUp/SIGN_UP_COMPLETED';
export const SIGN_UP_FAILED = 'signUp/SIGN_UP_FAILED';

// start email verification types
export const EMAIL_VERIFICATION = 'signUp/EMAIL_VERIFICATION';
export const EMAIL_VERIFICATION_COMPLETED = 'signUp/EMAIL_VERIFICATION_COMPLETED';
export const EMAIL_VERIFICATION_FAILED = 'signUp/EMAIL_VERIFICATION_FAILED';

// start RESEND LINK
export const RESEND_LINK = 'signUp/RESEND_LINK';
export const RESEND_LINK_COMPLETED = 'signUp/RESEND_LINK_COMPLETED';
export const RESEND_LINK_FAILED = 'signUp/RESEND_LINK_FAILED';

export const LOGIN_COMPLETED = 'signUp/LOGIN_COMPLETED';
export const SET_AGREEMENT_MODAL = 'signUp/SET_AGREEMENT_MODAL';
