import * as types from './types';
import createReducer from '../../utils/createReducer';

import initialState from './initialState';

const reducersMap = {
   // start sign up
   [types.SIGN_UP]: (state) => {
      return {
         ...state,
         errors: {},
         isInProgress: true,
         emailVerified: false,
      };
   },
   [types.SIGN_UP_COMPLETED]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         errors: {},
         data: payload,
         isInProgress: false,
         emailVerified: false,
      };
   },
   [types.SIGN_UP_FAILED]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         isInProgress: false,
         errors: payload.data,
         emailVerified: false,
      };
   },

   // start email verification
   [types.EMAIL_VERIFICATION]: (state) => {
      return {
         ...state,
         errors: {},
         isInProgress: true,
         emailVerified: false,
      };
   },
   [types.EMAIL_VERIFICATION_COMPLETED]: (state, action) => {
      const { payload } = action
      return {
         ...state,
         errors: {},
         verificationStatus: payload,
         emailVerified: true,
         isInProgress: false,
      };
   },
   [types.EMAIL_VERIFICATION_FAILED]: (state, action) => {
      const { payload: { data } } = action;
      return {
         ...state,
         isInProgress: false,
         emailVerified: false,
         errors: data,
      };
   },

   // resend link
   [types.RESEND_LINK]: (state) => {
      return {
         ...state,
         errors: {},
         isInProgress: true,
      };
   },
   [types.RESEND_LINK_COMPLETED]: (state) => {
      return {
         ...state,
         errors: {},
         isInProgress: false,
      };
   },
   [types.RESEND_LINK_FAILED]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         isInProgress: false,
         errors: payload.data,
      };
   },


   [types.LOGIN_COMPLETED]: (state, action) => {
      return {
         ...state,
         isInProgress: false,
         emailVerified: false,
         data: {},
      };
   },
   [types.SET_AGREEMENT_MODAL]: (state, action) => {
      return {
         ...state,
         openAgreementModal: action.payload,
      };
   },

};

export default createReducer(initialState)(reducersMap);
