import * as types from './types';

const siteDetailsInitStart = () => ({
   type: types.SITE_DETAILS_INIT_START,
});

const siteDetailsInitCompleted = (data) => ({
   type: types.SITE_DETAILS_INIT_COMPLETED,
   payload: data,
});

const siteDetailsInitFailed = () => ({
   type: types.SITE_DETAILS_INIT_FAILED,
});

const resetCommonDetails = () => ({
   type: types.RESET_COMMON_DETAILS,
});

const collapseMenu = () => ({
   type: types.COLLAPSE_MENU,
});
const updateAuthUser = (data) => ({
   type: types.UPDATE_AUTH_USER,
   payload: data,
});

const screenResize = (screenWidth) => ({
   type: types.SCREEN_RESIZE,
   payload: {
      screenWidth,
   },
});

const updateWalletBalanceStart = () => ({
   type: types.UPDATE_WALLET_BALANCE_START,
});

const updateWalletBalanc = (data) => ({
   type: types.UPDATE_WALLET_BALANC,
   payload: data,
});

const updateWalletBalanceFailed = () => ({
   type: types.UPDATE_WALLET_BALANCE_FAILED,
});

const setIsOnlineStreamAction =  bool => ({
   type: types.SET_IS_ONLINE_STREAM,
   payload: bool,
});

const setViewAsModal = (data) => ({
   type: types.SET_VIEW_AS_MODAL,
   payload: data,
});
const setUpdatePurchaseMeda = (data) => ({
   type: types.SET_UPDATE_MEDIA,
   payload: data,
});
const updateLiveStreamBannerState = (data) => ({
   type: types.UPDATE_LIVE_STERAM_BANNER_STATE,
   payload: data,
});
const closePromotionNotification = () => ({
   type: types.CLOSE_PROMOTION_NOTIFICATION,
})
const setIsSiteInited = (bool) => ({
   type: types.SITE_DETAILS_SET_IS_INITED,
   payload: bool,
})
const ageVerificationByIdStart = () => ({
   type: types.AGE_VERIFICATION_BY_ID_START,
})
const ageVerificationByIdCompleted = (status) => ({
   type: types.AGE_VERIFICATION_BY_ID_COMPLETED,
   payload: status,
})
const ageVerificationByIdFailed = () => ({
   type: types.AGE_VERIFICATION_BY_ID_FAILED,
})
const resetAgeVerificationStatusAction = () => ({
   type: types.RESET_AGE_VERIFICATION_STATUS,
})
const setIsAdultVerifiedAction = () => ({
   type: types.SET_IS_ADULT_VERIFIED,
})
const logoutStartAction = () => ({
   type: types.LOGOUT_START,
})
const logoutCompletedAction = () => ({
   type: types.LOGOUT_COMPLETED,
})
const setMenuDefaultAnimationClassAction = () => ({
   type: types.SET_MENU_DEFAULT_ANIMATION_CLASS,
})
const openClosePenndingPaymentModal = (payload) => ({
   type: types.OPEN_CLOSE_PENNDING_PAYMENT_MODAL,
   payload,
})

const changeOrdersTotlaCountAction = (payload) => ({
   type: types.CHANGE_ORDERS_TOTAL_COUNT,
   payload,
})

const changeNewOrdersCountAction = (payload) => ({
   type: types.CHANGE_NEW_ORDERS_REQUESTS_COUNT,
   payload,
})

const setIsOfflineModeAction = (data) => ({
   type: types.SET_IS_OFFLINE,
   payload: data,
})

const updatePaymentPendingDataAction = (payload) => ({
   type: types.UPDATE_PENDING_PAYMENT_DATA,
   payload,
})


export {
   siteDetailsInitStart,
   resetCommonDetails,
   siteDetailsInitCompleted,
   siteDetailsInitFailed,
   collapseMenu,
   updateAuthUser,
   screenResize,
   updateWalletBalanc,
   updateWalletBalanceStart,
   updateWalletBalanceFailed,

   setIsOnlineStreamAction,
   setViewAsModal,
   setUpdatePurchaseMeda,
   updateLiveStreamBannerState,
   closePromotionNotification,
   setIsSiteInited,
   ageVerificationByIdStart,
   ageVerificationByIdCompleted,
   ageVerificationByIdFailed,
   resetAgeVerificationStatusAction,
   setIsAdultVerifiedAction,
   logoutStartAction,
   logoutCompletedAction,
   setMenuDefaultAnimationClassAction,
   openClosePenndingPaymentModal,

   setIsOfflineModeAction,

   changeOrdersTotlaCountAction,
   updatePaymentPendingDataAction,
   changeNewOrdersCountAction,
};
