export const OPEN_CHAT_NOTIFICATION_MODAL = "chat/OPEN_CHAT_NOTIFICATION_MODAL";

export const CHAT_INIT_REQUEST = "chat/CHAT_INIT_REQUEST";
export const CHAT_INIT_COMPLETED = "chat/CHAT_INIT_COMPLETED";
export const CHAT_INIT_FAILED = "chat/CHAT_INIT_FAILED";

export const FETCH_MESSAGES_REQUEST = "chat/FETCH_MESSAGES_REQUEST";
export const FETCH_MESSAGES_COMPLETED = "chat/FETCH_MESSAGES_COMPLETED";
export const FETCH_MESSAGES_FAILED = "chat/FETCH_MESSAGES_FAILED";

export const FETCH_NEXT_MESSAGES_REQUEST = "chat/FETCH_NEXT_MESSAGES_REQUEST";
export const FETCH_NEXT_MESSAGES_COMPLETED = "chat/FETCH_NEXT_MESSAGES_COMPLETED";
export const FETCH_NEXT_MESSAGES_FAILED = "chat/FETCH_NEXT_MESSAGES_FAILED";

export const FETCH_MESSAGE_LIKED_MEMBERS_REQUEST = "chat/FETCH_MESSAGE_LIKED_MEMBERS_REQUEST";
export const FETCH_MESSAGE_LIKED_MEMBERS_COMPLETED = "chat/FETCH_MESSAGE_LIKED_MEMBERS_COMPLETED";
export const FETCH_MESSAGE_LIKED_MEMBERS_FAILED = "chat/FETCH_MESSAGE_LIKED_MEMBERS_FAILED";


export const SET_ONLINE_USERS_ACTIONS = "chat/SET_ONLINE_USERS_ACTIONS";
export const SET_SOCKET_CONNECTED_ACTION = "chat/SET_SOCKET_CONNECTED_ACTION";
export const SET_MESSAGE_LIKE_ACTIONS = "chat/SET_MESSAGE_LIKE_ACTIONS";
export const DELETE_MESSAGE = "chat/DELETE_MESSAGE";
export const ADD_NEW_MESSAGE = "chat/ADD_NEW_MESSAGE";
export const UPDATE_MESSAGE_DATA = "chat/UPDATE_MESSAGE_DATA";
export const UPDATE_CONVERSTION_DATA = "chat/UPDATE_CONVERSTION_DATA";
export const UPDATE_PRIVATE_CONVERSATION_ID = "chat/UPDATE_PRIVATE_CONVERSATION_ID";
export const SET_MUTED_MEMBER = "chat/SET_MUTED_MEMBER";
export const SET_CHAT_PANEL = "chat/SET_CHAT_PANEL";
export const CLEAR_CHAT_INFO = "chat/CLEAR_CHAT_INFO";
export const SET_ACTIVE_TAB = "chat/SET_ACTIVE_TAB";
export const SET_CHAT_SETTINGS = "chat/SET_CHAT_SETTINGS";
export const SET_CHAT_ICON_STATE = "chat/SET_CHAT_ICON_STATE";
export const OPEN_CLOSED_CONVERSATION_COMPLETED = "chat/OPEN_CLOSED_CONVERSATION_COMPLETED";
