import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Router from 'sites/routes/router';
import { push } from 'connected-react-router';
import ErrorPage from 'common/components/modules/error-page';

class NotFoundContainer extends Component {

   static propTypes = {
      goTo: PropTypes.func.isRequired,
   };

   render() {
      const {
         goTo,
      } = this.props
      return (
         <ErrorPage
            status='not_found'
            goTo={ () => goTo(Router.route('HOMEPAGE').getCompiledPath()) }
         />
      )
   }
}
const mapStateToProps = (state) => {
   return {
   }
}

const mapDispatchToProps = (dispatch) => {
   return {
      goTo: (location) => {
         dispatch(push(location))
      },
   }
}


export default connect(mapStateToProps, mapDispatchToProps)(NotFoundContainer);
